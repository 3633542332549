jQuery(document).on('gform_post_render', function(event, form_id, current_page) {

    // page ID 1608 is https://www.salesgenie.com/expired-b/
    // page ID 1641 is https://www.salesgenie.com/expired-d/
    if (WordPressPageID != '1608' && WordPressPageID != '1641') {

        let the_submit_button_id = '#gform_submit_button_' + form_id;

        jQuery(the_submit_button_id).prop('disabled', true);

        let the_submit_button = document.getElementById('gform_submit_button_' + form_id);

        let the_form_id = 'gform_' + form_id;

        let the_form = document.getElementById(the_form_id);

        function disabled_submit_button(button_hovered) {

            let visible_fields_passing = true;

            let visible_fields = jQuery('#' + the_form_id + ' input:visible').not('#gform_submit_button_' + form_id).not('#input_18_1');

            if (button_hovered) {

                visible_fields.each(function() {

                    let the_field_id_numbers = jQuery(this).attr('id').substring(5);

                    if (!jQuery(this).hasClass('da-validation-passed') && !jQuery('#validation_message' + the_field_id_numbers).length) {

                        jQuery(this).addClass('da-validation-error');
        
                    } else if (!visible_fields.hasClass('da-validation-error')) {

                        jQuery(the_submit_button_id).prop('disabled', false);

                    }
        
                });
                
            } else {

                visible_fields.each(function() {

                    if (!jQuery(this).hasClass('da-validation-passed')) {
                        
                        visible_fields_passing = false;

                        return false;

                    }

                });

                if (visible_fields_passing) {

                    jQuery(the_submit_button_id).prop('disabled', false);

                } else {

                    jQuery(the_submit_button_id).prop('disabled', true);

                }

            }

        }

        function gravity_forms_validator(the_form_id, event) {

            let the_field_type = event.target.type;

            let the_field_id = event.target.id;

            let the_field_id_numbers = the_field_id.substring(5);

            let the_field_error_message = document.getElementById('validation_message' + the_field_id_numbers);

            function phone_field_failure() {
            
                jQuery('#' + the_field_id).removeClass('da-validation-passed').addClass('da-validation-error');
        
                if (the_field_error_message == null) {
        
                    jQuery('#' + the_field_id).after('<div id="validation_message' + the_field_id_numbers + '" class="gfield_description validation_message gfield_validation_message">A valid 10-digit phone number is required.</div>');
        
                    disabled_submit_button();
        
                }
            }
            
            switch (the_field_type) {

                case 'text':

                    if (validator.isEmpty(validator.trim(event.target.value))) {

                        jQuery('#' + the_field_id).removeClass('da-validation-passed').addClass('da-validation-error');

                        if (the_field_error_message == null) {

                            jQuery('#' + the_field_id).after('<div id="validation_message' + the_field_id_numbers + '" class="gfield_description validation_message gfield_validation_message">This field is required.</div>');

                            disabled_submit_button();

                        }

                    } else {

                        jQuery('#' + the_field_id).addClass('da-validation-passed').removeClass('da-validation-error');

                        jQuery('#validation_message' + the_field_id_numbers).remove();

                        disabled_submit_button();

                    }

                    break;
                
                case 'email':

                    let email_to_string = new String(event.target.value);

                    const is_valid_email = validator.isEmail(email_to_string.toString());

                    if (!is_valid_email) {

                        jQuery('#' + the_field_id).removeClass('da-validation-passed').addClass('da-validation-error');

                        if (the_field_error_message == null) {

                            jQuery('#' + the_field_id).after('<div id="validation_message' + the_field_id_numbers + '" class="gfield_description validation_message gfield_validation_message">A valid email address is required.</div>');

                            disabled_submit_button();

                        }

                    } else {

                        jQuery('#' + the_field_id).addClass('da-validation-passed').removeClass('da-validation-error');

                        jQuery('#validation_message' + the_field_id_numbers).remove();

                        disabled_submit_button();

                    }

                    break;

                case 'tel':

                    let the_phone_number = event.target.value;

                    let phone_to_string = new String(the_phone_number);

                    phone_to_string = phone_to_string.toString().replace(/\D/g, "");

                    let cloudflare_worker_endpoint, twilio_log_receiver_endpoint;

                    if (cmsEnvironment == 'PROD') {

                        cloudflare_worker_endpoint = 'https://twilio-phone-validation-prod.dl-sa-cloud-devops.workers.dev/?phone=' + phone_to_string;

                        twilio_log_receiver_endpoint = 'https://www.salesgenie.com/wp-content/themes/infogroup_theme/infogroup-includes/twilio-log-receiver.php';

                    }
                    else if(cmsEnvironment == 'TEST'){

                        cloudflare_worker_endpoint = 'https://twilio-phone-validation-dev-test.dl-sa-cloud-devops.workers.dev/?phone=' + phone_to_string;

                        twilio_log_receiver_endpoint = 'https://test.salesgenie.com/wp-content/themes/infogroup_theme/infogroup-includes/twilio-log-receiver.php';
                    }
                    else {

                        cloudflare_worker_endpoint = 'https://twilio-phone-validation-dev-test.dl-sa-cloud-devops.workers.dev/?phone=' + phone_to_string;

                        twilio_log_receiver_endpoint = 'https://dev.salesgenie.com/wp-content/themes/infogroup_theme/infogroup-includes/twilio-log-receiver.php';

                    }

                    if (phone_to_string.length > 0) {

                        jQuery.ajax({

                            //url: 'https://' + current_domain + '/wp-content/themes/landing_pages_theme/infogroup-includes/validate-phone.php?phone=' + phone_to_string,
                            url: cloudflare_worker_endpoint,
                            type: 'POST',
                            contentType: 'application/json',
                            success: function(data) {
                                
                                //console.log('Success: ' + JSON.stringify(data));
                                    
                                if (!data.valid) {

                                    //phone_field_failure();

                                    let the_email = jQuery('#' + the_form_id + ' input[type="email"]').val();

                                    jQuery.ajax({
                                        url: twilio_log_receiver_endpoint,
                                        type: 'POST',
                                        data: {
                                            tr: JSON.stringify(data),
                                            em: (the_email.length > 0) ? the_email : 'No email address in the field at this time.',
                                            env: cmsEnvironment
                                        },
                                        success: function(data) {
                                            //console.log('Log successfully written.' + data);
                                        },
                                        error: function(xhr, status, error) {
                                            //console.error('Error writing log: ' + error);
                                        }
                                    });

                                    jQuery('#' + the_field_id).addClass('da-validation-passed').removeClass('da-validation-error');

                                    jQuery('#validation_message' + the_field_id_numbers).remove();

                                    disabled_submit_button();

                                } else {

                                    jQuery('#' + the_field_id).addClass('da-validation-passed').removeClass('da-validation-error');

                                    jQuery('#validation_message' + the_field_id_numbers).remove();

                                    disabled_submit_button();

                                }
                
                            },

                            error: function(data) {

                                //console.log('Error: ' + JSON.stringify(data));

                                phone_field_failure();

                            }
                
                        });

                    } else {

                        phone_field_failure();

                    }

                    break;

                case 'password':

                    if (validator.isEmpty(event.target.value)) {

                        jQuery('#' + the_field_id).removeClass('da-validation-passed').addClass('da-validation-error');

                        if (the_field_error_message == null) {

                            jQuery('#' + the_field_id).after('<div id="validation_message' + the_field_id_numbers + '" class="gfield_description validation_message gfield_validation_message">Password must contain a minimum of 8 characters, including at least 1 number.</div>');

                            disabled_submit_button();

                        }

                    } else {

                        jQuery('#' + the_field_id).addClass('da-validation-passed').removeClass('da-validation-error');

                        jQuery('#validation_message' + the_field_id_numbers).remove();

                        disabled_submit_button();

                    }

                    break;

                default:

                    //console.log(`The field type of ${the_field_type} is not validated.`);

            }

        }

        the_form.addEventListener('focusout', (event) => {

            gravity_forms_validator(the_form_id, event);
            
        });

        the_form.addEventListener('onautocomplete', (event) => {

            gravity_forms_validator(the_form_id, event);
            
        });

        the_form.addEventListener('input', (event) => {

            let the_field_type = event.target.type;

            if (the_field_type != 'email') {

                gravity_forms_validator(the_form_id, event);

            } else if (the_field_type == 'email') {

                let is_valid_email = validator.isEmail(event.target.value);

                if (is_valid_email) {

                    gravity_forms_validator(the_form_id, event);

                }

            }    
            
        });

        the_submit_button.addEventListener('mouseenter', (event) => {

            disabled_submit_button(true);
            
        });

    } // end of current_page if condition at top of file

}); // end gform_post_render hook
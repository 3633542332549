jQuery('.sg-live-chat, a[href="#sg-live-chat"]').on('click touchstart touchend', function(event) {

	ga('send', 'event', 'cms chat', 'click', currentPageURL);

	if (cmsEnvironment == 'DEV' || cmsEnvironment == 'TEST') {
	
		var sgChatURL = 'https://test-chats.data-axle.com/?site=Salesgenie';
	
	} else {
	
		var sgChatURL = 'https://chats.data-axle.com/?site=Salesgenie';
	
	}

	var paramURL =	"&SFCID=" + (Cookies.get("sfcid") || "70160000000SqFaAAK") +
				"&MediaCode=" + (Cookies.get("mediacode") || "15WEB") +
				"&GclidID=" + (Cookies.get("gclid") || "not present") +
				"&MSCLKID=" + (Cookies.get("msclkid") || "not present") +
				"&FBC=" + (Cookies.get("_fbc") || "not present") +
				"&FBP=" + (Cookies.get("_fbp") || "not present") +
				"&BasPhone=" + (Cookies.get("bas_phone") || "not present") +
				"&UTMCampaign=" + (Cookies.get("utm_campaign") || "not present") +
				"&UTMContent=" + (Cookies.get("utm_content") || "not present") +
				"&UTMMedium=" + (Cookies.get("utm_medium") || "not present") +
				"&UTMSource=" + (Cookies.get("utm_source") || "not present") +
				"&UTMTerm=" + (Cookies.get("utm_term") || "not present") +
				"&ValueTrackAdGroupID=" + (Cookies.get("adgroupid") || "not present") +
				"&ValueTrackCampaignID=" + (Cookies.get("campaignid") || "not present") +
				"&ValueTrackDevice=" + (Cookies.get("device") || "not present") +
  				"&ValueTrackExtensionID=" + (Cookies.get("extensionid") || "not present") +
				"&ValueTrackKeyword=" + (Cookies.get("keyword") || "not present") +
				"&ValueTrackMatchtype=" + (Cookies.get("matchtype") || "not present") +
				"&ValueTrackNetwork=" + (Cookies.get("network") || "not present") +
				"&FormTitle =" + ("Genie CMS Chat") +
				"&IPAddress=" + (connectingIP) +
				"&BrowserUserAgent=" + (browserUserAgent) +
				"&PageURLLongText=" + (currentPageURL);
	
	jQuery('#loadingMessage').show();
	jQuery('#chatIframe').hide();
			  
	jQuery('#chatIframe').on('load', function() {
		jQuery('#loadingMessage').hide();
		jQuery('#chatIframe').show();
	});
	
	jQuery('#chatIframe').attr('src', sgChatURL + paramURL);
	jQuery('#footer-chat-modal').foundation('open');
	
	event.preventDefault();

});
jQuery(document).ready(function() {
	
	// ******* START BAS_PHONE ****************************************

	// use object key:pair for pages that require static phone numbers
	// key:pair format is pageID:staticPhoneNumber
	// put comment after key:pair with page URL for reference
	var staticPhonePages = {
		'123':'866.872.9069', // https://www.salesgenie.com/product/enterprise-package/
		'108':'866.872.9069', // https://www.salesgenie.com/product/crm/
		'2099':'866.793.4558', // https://www.salesgenie.com/enterprise-data-users/
	};

	// set variable to bas_phone cookie value
	var awBasPhoneCookie = Cookies.get('bas_phone');
	
	// set variable to bas_phone query string parameter value
	var awBasPhoneQSP = getParameterByName('bas_phone');

	// static phone number pages that differ from the main phone number
	// and do not accept cookie or query string phone number changes
	if (WordPressPageID in staticPhonePages) {

		jQuery('a').each(function() {

			if (jQuery(this).hasClass('bas_phone') || jQuery(this).hasClass('bas-phone')) {			
			
				jQuery(this).text(staticPhonePages[WordPressPageID]).attr('href', 'tel:+1' + staticPhonePages[WordPressPageID]);

			}
			
		});
		
		jQuery('.bas-phone-no-link').text(staticPhonePages[WordPressPageID]);
		
	}

	// if bas_phone cookie exists and bas_phone is not present
	// on the query string set all phone numbers with the bas_phone
	// CSS class to the value of the bas_phone cookie.
	else if (awBasPhoneCookie && !awBasPhoneQSP) {
		
		jQuery('a').each(function() {

			if (jQuery(this).hasClass('bas_phone') || jQuery(this).hasClass('bas-phone') || jQuery(this).parent().hasClass('bas_phone') || jQuery(this).parent().hasClass('bas-phone')) {
			
				jQuery(this).text(awBasPhoneCookie).attr('href', 'tel:+1' + awBasPhoneCookie);
			
			}

		});
		
		jQuery('.bas-phone-no-link').text(awBasPhoneCookie);

	// if previous condition is not met check if the bas_phone query string parameter
	// exists and has a value, then set the bas_phone cookie to that vallue and	
	// set all phone numbers with the bas_phone CSS class to the value of the bas_phone qsp
	} else if (awBasPhoneQSP) {
	
		Cookies.set('bas_phone', awBasPhoneQSP, { domain: cookieDomain, expires: 30, path: '/' });
		
		jQuery('a').each(function() {

			if (jQuery(this).hasClass('bas_phone') || jQuery(this).hasClass('bas-phone') || jQuery(this).parent().hasClass('bas_phone') || jQuery(this).parent().hasClass('bas-phone')) {
			
				jQuery(this).text(awBasPhoneQSP).attr('href', 'tel:+1' + awBasPhoneQSP);
			
			}

		});
		
		jQuery('.bas-phone-no-link').text(awBasPhoneQSP);
		
	}
	// ******* END BAS_PHONE ****************************************
	   
}); // end .ready
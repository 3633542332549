// video event tracking for ga/gtm
function sendVideoEvent(action, element) {

    var videoTitle = jQuery(element).data('title');

    ga('send', {
        hitType: 'event',
        eventCategory: 'Video',
        eventAction: action,
        eventLabel: videoTitle + ': ' + element.currentSrc,
        nonInteraction: false
    });

}
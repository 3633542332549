// Redirect users on the sign in and register page if they are logged into NXG
if (WordPressPageID == '51' || WordPressPageID == '264') {	

	var nxg_access_token = Cookies.get('nxg-' + nxg_environment + '-access-token');

	var nxg_temp_auth_sig = Cookies.get('nxg-' + nxg_environment + '-tempauthsignature-token');

	if (nxg_access_token || nxg_temp_auth_sig) {

		window.location.href = 'https://' + nxg_subdomain + '.salesgenie.com/';

	}

} else if (WordPressPageID == '12417') { // Redirect users on the NXG thank you page page if they are logged into NXG

	var nxg_temp_auth_sig = Cookies.get('nxg-' + nxg_environment + '-tempauthsignature-token');

	var nxg_user = Cookies.get('nxg-username');

	console.log('NXG User: ' + nxg_user);

	if (nxg_temp_auth_sig) {

		window.location.href = 'https://' + window.location.hostname +  '/';

	} else {

		if (!nxg_user) {

			window.location.href = 'https://' + window.location.hostname +  '/register/?force=true';

		}

	}

}

// NXG access token by refresh token when clicking "Back to Dashboard" as an authenticated user
jQuery('a[href="#back-to-nxg-dashboard"]').on('click touchstart touchend', function(event) { 
	
	event.preventDefault();

	var nxg_refresh_token  = Cookies.get('nxg-' + nxg_environment + '-refresh-token');

	var nxg_access_token  = Cookies.get('nxg-' + nxg_environment + '-access-token');

	var nxg_tempauthsig_token  = Cookies.get('nxg-' + nxg_environment + '-tempauthsignature-token');

	const current_domain = window.location.hostname;

	if (nxg_refresh_token) {

		jQuery.ajax({

			url: 'https://' + current_domain + '/wp-content/themes/infogroup_theme/infogroup-includes/nxg/validate-logged-in-user.php',
			type: 'POST',
			data: {
				'nxg_refresh_token': nxg_refresh_token,
			},
			success: function(data) {
				
				var data_json = JSON.parse(data);
			        
				if (data_json.refresh_success) {

					window.location.href = 'https://' + nxg_subdomain + '.salesgenie.com/';

				} else if (!data[refresh_success]) {

					window.location.href = 'https://' + current_domain + '/sign-in/?nxg-logged-out=true';

				}

			},
			error: function(data) {
				console.log('error: ' + data);                        
			}

		});

	} else if (nxg_access_token || nxg_tempauthsig_token){

		window.location.href = 'https://' + nxg_subdomain + '.salesgenie.com/';

	} else {

		window.location.href = 'https://' + current_domain + '/sign-in/?nxg-logged-out=true';

	}

});

// Pre/Post Auth
jQuery('.post-auth').hide();
jQuery('.pre-auth').show();

function pre_post_auth() {
		
	// get the IMG and NXG auth cookies
	var cmsCookie = Cookies.get('CmsCookie');
	var nxg_access_token  = Cookies.get('nxg-' + nxg_environment + '-access-token');
	var nxg_tempauthsig_token  = Cookies.get('nxg-' + nxg_environment + '-tempauthsignature-token');

	// IMG and NXG Back to Dashboard and Logout
	jQuery('.ubermenu').find('#menu-item-11881, #menu-item-11882, #menu-item-12571, #menu-item-12570').hide();

	if (cmsCookie != null && cmsCookie.length > 0) {

		// decode and parse the cookie as JSON        
		var cmsCookieParsed = JSON.parse(decodeURIComponent(cmsCookie));
		
		// check the IsAuthenticated cookie value and
		// show/hide elements based on that value
		if (cmsCookieParsed.IsAuthenticated) {
				
			// post auth stuff!
			jQuery('.post-auth').show();
			jQuery('.pre-auth').hide();

			// Sign In, Try Us, NXG Back to Dashboard, and NXG Logout menu items
			jQuery('.ubermenu').find('#menu-item-11878, #menu-item-11880, #menu-item-12571, #menu-item-12570').hide();

			// IMG Back to Dashboard and Logout
			jQuery('.ubermenu').find('#menu-item-11881, #menu-item-11882').show().css("display", "flex");
			
		}	
		
	}

	if (nxg_access_token || nxg_tempauthsig_token) {

		// post auth stuff!
		jQuery('.post-auth').show();
		jQuery('.pre-auth').hide();

		// Sign In, Try Us, IMG Back to Dashboard, and IMG Logout menu items
		jQuery('.ubermenu').find('#menu-item-11878, #menu-item-11880,#menu-item-11881, #menu-item-11882').hide();

		// NXG Back to Dashboard and Logout
		jQuery('.ubermenu').find('#menu-item-12571, #menu-item-12570').show().css("display", "flex");

	}

};

window.onload = (event) => {
	pre_post_auth();
};

// debounced resize, fires once after all resizing stops
jQuery(window).smartresize(function() {
	pre_post_auth();
});

//var currentURL = window.location.href;

var pageAnchorLocation = window.location.hash.substr(1);

function ig_scroll_to(event, thisObj, anchorLocation) {

    if (thisObj) {

        var dataScrollTo = thisObj['0']['attributes']['data-scroll-to']['value'];
    
        if (dataScrollTo) {
    
            var href = thisObj['0']['hash'];
    
            jQuery('html, body').animate({
                scrollTop: jQuery(href).offset().top - 135
            }, 1500);
    
            event.preventDefault();
    
        }

    } else if (anchorLocation) {

        setTimeout(function() {

            jQuery('html, body').animate({
                scrollTop: jQuery('#' + anchorLocation).offset().top - 135
            }, 1500);

        }, 1);

    }

}

if (pageAnchorLocation) {

    ig_scroll_to('', '', pageAnchorLocation);
    
}

jQuery('a').click(function(event) {

    if (jQuery(this).hasClass('scrollToElement') || jQuery(this).parent().hasClass('scrollToElement')) {

        jQuery(this).attr('data-scroll-to', 'true');

        ig_scroll_to(event, jQuery(this), '');

    }

});

jQuery(document).scroll(function() {

    var awPosition = jQuery(this).scrollTop();

    if (awPosition > 100) {

        jQuery('#floating-call, #floating-chat').fadeIn();

    } else {

        jQuery('#floating-call, #floating-chat').fadeOut();

    }

});
if (jQuery('.row').hasClass('equalHeights') || jQuery('.row').parent().hasClass('equalHeights')) {

    jQuery(this).attr('data-equalizer', 'true');

    jQuery('.equalHeights').each(function() {
       
        var newEqualHeightsRow = new Foundation.Equalizer(jQuery(this));

        newEqualHeightsRow.applyHeight();
        
    });

}

function masonry_start_stop() {

	var archiveWindowWidth = jQuery(window).outerWidth();

	var masonryOptions = {
  		itemSelector: '.masonry-item',
  		percentPosition: true,
  		gutter: '.gutter-sizer-3-percent',
	};

	var $masonryGrid = jQuery('#masonry-container').masonry(masonryOptions);

	if (archiveWindowWidth > 1139) {

		$masonryGrid.masonry(masonryOptions);

	} else if (archiveWindowWidth < 1140) {

		$masonryGrid.masonry('destroy');

	}

}

jQuery(document).ready(function() {
	
	masonry_start_stop();
	
	// debounced resize, fires once after all resizing stops
	jQuery(window).smartresize(function() {

		masonry_start_stop();

	});

});
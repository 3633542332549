function sendWebVitalsCLStoHeap({name, delta, value, rating}) {
    heap.track("Web Vitals - CLS", {
        "Name":name,
        "Value":delta,
        "Rating":rating
    });
};

function sendWebVitalsFIDtoHeap({name, delta, value, rating}) {
    heap.track("Web Vitals - FID", {
        "Name":name,
        "Value":delta,
        "Rating":rating
    });
};

function sendWebVitalsFCPtoHeap({name, delta, value, rating}) {
    heap.track("Web Vitals - FCP", {
        "Name":name,
        "Value":delta,
        "Rating":rating
    });
};

function sendWebVitalsLCPtoHeap({name, delta, value, rating}) {
    heap.track("Web Vitals - LCP", {
        "Name":name,
        "Value":delta,
        "Rating":rating
    });
};

function sendWebVitalsINPtoHeap({name, delta, value, rating}) {
    heap.track("Web Vitals - INP", {
        "Name":name,
        "Value":delta,
        "Rating":rating,
        "Value":value
    });
};

function sendWebVitalsTTFBtoHeap({name, delta, value, rating}) {
    heap.track("Web Vitals - TTFB", {
        "Name":name,
        "Value":delta,
        "Rating":rating
    });
};

webVitals.onCLS(sendWebVitalsCLStoHeap);
webVitals.onFID(sendWebVitalsFIDtoHeap);
webVitals.onFCP(sendWebVitalsFCPtoHeap);
webVitals.onLCP(sendWebVitalsLCPtoHeap);
webVitals.onINP(sendWebVitalsINPtoHeap);
webVitals.onTTFB(sendWebVitalsTTFBtoHeap);